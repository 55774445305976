import React from "react";
import styled from "styled-components";

import Constants from "../constants";

const Submit = styled.button`
  font-weight: 700;
  text-transform: uppercase;
  cursor: pointer;
  border: 1px solid ${Constants.Colors.headerGray};
  border-radius: 5px;
  padding: 1rem 1rem 0.75rem;
  font-weight: 100;
  background: none;
`;

const SubmitButton = ({ text, type, ...rest }) => (
  <Submit type="submit" {...rest}>
    {text}
  </Submit>
);

export default SubmitButton;
