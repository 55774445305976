import React from "react";
import styled from "styled-components";

import Constants from "../constants";
import SubmitButton from "./submit-button";

const ContactUsSection = styled.div`
  margin-top: 1rem;

  & > form > p > label > input,
  textarea {
    display: block;
    border: 1px solid ${Constants.Colors.headerGray};
    border-radius: 5px;
  }
`;

const TextArea = styled.textarea`
  display: block;
  min-height: 150px;
  min-width: 500px;

  @media (max-width: ${Constants.mobileWidth}) {
    min-width: 290px;
  }
`;

const ContactUs = () => (
  <ContactUsSection id="contact-us">
    <h2>Contact Us</h2>
    <p>
      Looking to get in touch? Look no further! Drop us a little note by filling
      out the form below, we'd love to hear from you. Alternatively, feel free
      to reach out to us by email:
      <i>hello@sheandjim.com </i>!
    </p>
    <form
      name="contact-us"
      method="POST"
      data-netlify="true"
      netlify-honeypot="bot-field"
      action="/thank-you"
    >
      <p style={{ display: "none" }}>
        <label id="honeypot" htmlFor="bot-field">
          Don’t fill this out if you expect to hear from us!
          <input aria-labelledby="honeypot" name="bot-field" />
        </label>
      </p>
      <input
        style={{ display: "none" }}
        name="form-name"
        value="contact-us"
        readOnly={true}
        aria-label="formName"
      />
      <p>
        <label id="nameLabel" htmlFor="name">
          Your Name:{" "}
          <input
            type="text"
            aria-labelledby="nameLabel"
            id="name"
            name="name"
            required={true}
          />
        </label>
      </p>
      <p>
        <label id="emailLabel" htmlFor="email">
          Your Email:{" "}
          <input
            aria-labelledby="emailLabel"
            type="email"
            id="email"
            name="email"
            required={true}
          />
        </label>
      </p>
      <p>
        <label htmlFor="message">
          Message:{" "}
          <TextArea required={true} id="message" name="message"></TextArea>
        </label>
      </p>
      <SubmitButton text="Send" name="SendMessage" />
    </form>
  </ContactUsSection>
);

export default ContactUs;
