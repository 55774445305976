import { graphql } from "gatsby";
import React from "react";

import ContactUs from "../components/contact-us";
import Subscribe from "../components/subscribe";
import Layout from "../components/layout";
import SEO from "../components/seo";

const ChaseUs = ({ data: { avatar, backdrop, logo, site }, location }) => (
  <Layout avatar={avatar} backdrop={backdrop} location={location} site={site}>
    <SEO
      avatar={avatar}
      description="Chase us around the world. Here you can subscribe to our email newsletter, or fill out a form to contact us"
      logo={logo}
      postData={{ keywords: ["contact us", "subscribe"] }}
      site={site}
      title={`Chase Us - She & Jim`}
    />
    <Subscribe />
    <ContactUs />
  </Layout>
);

export const pageQuery = graphql`
  query ChaseUsQuery {
    avatar: file(absolutePath: { regex: "/profile-pic.jpg/" }) {
      ...Avatar
    }

    backdrop: file(absolutePath: { regex: "/mountains.png/" }) {
      ...Backdrop
    }

    logo: file(absolutePath: { regex: "/she-and-jim-logo.jpg/" }) {
      ...Logo
    }

    site {
      siteMetadata {
        authors
        social {
          instagram
        }
        siteUrl
        subTitle
        title
      }
    }
  }
`;

export default ChaseUs;
