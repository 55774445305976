import React, { useState } from "react";
import styled from "styled-components";

import Constants from "../constants";
import { fetchHelper } from "../utils/fetchHelper";
import SubmitButton from "./submit-button";

const ResponseArea = styled.div`
  border-color: ${({ error }) =>
    error && error ? "red" : Constants.Colors.headerTheme};
  border-width: 1px;
  border-style: ${({ mailChimpResponse }) =>
    mailChimpResponse ? "solid" : "hidden"};
  display: block;
  margin: ${({ mailChimpResponse }) => (mailChimpResponse ? "1rem 0" : "0")};
  outline: none;
`;

const ResponseContainer = styled.div`
  margin: 5px 0;
`;

const ResponseText = styled.div`
  margin: 5px 0;
  padding: 10px;
`;

const ResponseInput = styled.input`
  display: block;
  border: 1px solid ${Constants.Colors.headerGray};
  border-radius: 5px;
`;

const mailChimpError = `A problem occurred when confirming your email subscription. Are you sure you aren't already subscribed with this email? Check if your email address is correct and try re-submitting the form.`;
const mailChimpAlreadySubbed = `It looks like you're already subscribed! Please <a href="#contact-us">Contact Us</a> if you're having issues receiving our emails.`;
const mailchimpSuccess =
  "You've been subscribed! Congrats, and thanks for signing up to hear from She & Jim!";

const postToMailChimp = async (formValues, setError, setMailchimpResponse) => {
  const response = await fetchHelper({
    url: "/.netlify/functions/subscribe-handler",
    body: formValues
  });

  if (!response.success) {
    setError(true);
    setMailchimpResponse(mailChimpError);
  } else {
    setError(false);
    const text = response.memberExists
      ? mailChimpAlreadySubbed
      : mailchimpSuccess;
    setMailchimpResponse(text);
  }
};

const onclickHandler = async (
  event,
  responseAreaId,
  setError,
  setMailchimpResponse
) => {
  event.preventDefault();
  setError(false);
  setMailchimpResponse(`Subscribing you to She & Jim ...`);
  const responseArea = document.getElementById(responseAreaId);
  responseArea && responseArea.focus();

  const formValues = {};
  let emailMissing = false;

  for (
    let index = 0;
    index < event.target.parentElement.elements.length - 1;
    index++
  ) {
    const element = event.target.parentElement.elements[index];
    if (index === 0 && !element.validity.valid) {
      emailMissing = true;
    }
    formValues[element.name] = element.value;
  }

  if (emailMissing) {
    setError(true);
    setMailchimpResponse(
      `Please check your email address, it looks like there was a formatting error! Correct it and then resubmit the form.`
    );
  } else {
    await postToMailChimp(formValues, setError, setMailchimpResponse);
  }

  return false;
};

const Subscribe = () => {
  const responseAreaId = "responseArea";
  const [error, setError] = useState(false);
  const [mailChimpResponse, setMailchimpResponse] = useState(null);

  return (
    <div id="subscribe">
      <h2>Join Our Mailing List</h2>
      <p>
        We can fill your inbox with heart-warming vegetarian and vegan recipes,
        tell you when we have new blog posts, and wax poetic about the
        importance of saving money - all ad-free. Just fill out the form below
        to get She & Jim delivered straight to your inbox!
      </p>

      <form noValidate={true}>
        <span>* indicates required</span>
        <ResponseContainer>
          <label htmlFor="EMAIL">
            Email Address
            <span>*</span>
          </label>
          <ResponseInput type="email" name="EMAIL" id="EMAIL" required={true} />
        </ResponseContainer>
        <ResponseContainer>
          <label htmlFor="FNAME">First Name </label>
          <ResponseInput type="text" name="FNAME" id="FNAME" />
        </ResponseContainer>
        <ResponseContainer>
          <label htmlFor="LNAME">Last Name </label>
          <ResponseInput type="text" name="LNAME" id="LNAME" />
        </ResponseContainer>
        <ResponseArea
          error={error}
          id={responseAreaId}
          mailChimpResponse={mailChimpResponse}
          tabIndex={mailChimpResponse && -1}
        >
          <ResponseText
            dangerouslySetInnerHTML={{ __html: mailChimpResponse }}
          />
        </ResponseArea>
        <SubmitButton
          text="Subscribe!"
          onClick={e =>
            onclickHandler(e, responseAreaId, setError, setMailchimpResponse)
          }
        />
      </form>
    </div>
  );
};

export default Subscribe;
